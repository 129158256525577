import { Fragment, useCallback, useContext, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import Page from "../../../../layout/Page/Page";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import ErrorMessage from "../../../../components/ErrorMessage";
import { CustomTable } from "../../../../components/table/CustomTable";
import { toast } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import useFilters from "../../../../hooks/useFilters";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import { FixedCostsApiResponse } from "../../../../type/cost-type";
import { CostService } from "../../../../services/costs/costService";
import CreateModalFixedCost from "../fixed-cost-create/createModal";
import moment from "moment";
import Spinner from "../../../../components/bootstrap/Spinner";
import FixedCostFilters from "./fixed-cost-options/FixedCostFilters";
import { FixNumber } from "../../../../utils/fixNumber";

export interface IFixedCostFilters {
    active?: number;
}

const fixedCostFilters: IFixedCostFilters = {
    active: 1
};

const FixedCostList = () => {

    const { userCan } = useContext(PrivilegeContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(true);
    const costService = new CostService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(fixedCostFilters);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await costService.getFixedCosts(filters);
        setIsLoading(false);
        return response.getResponseData() as FixedCostsApiResponse;
    }, [filters]));

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new CostService()).deleteFixedCost(id)).getResponseData();
            if (response.success) {
                refetch();
                setTimeout(() => {
                    toast.success('Coste fijo eliminado correctamente');
                }, 100);
            } else {
                toast.error(response.message || "Error al eliminar el coste fijo");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _onCloseModal = (type: number, message?: string, errors?: any) => {
        if (type === 1) {
            setSelectedItem(null);
            setShow(false);
            setModalOpen(false);
            refetch();
            toast.success(message);
        } else if (type === 0) {
            message !== '' && toast.error(message);
            errors && errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage error={error} />;
        if (isLoading) return <Spinner />;

        if (data) {
            let apiData = data as FixedCostsApiResponse;

            return (
                <CustomTable
                    title="Costes Fijos"
                    data={apiData ? apiData.costsFixed : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as FixedCostsApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Concepto",
                            keyValue: "concept",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-primary fw-bold text-center" onClick={() => {
                                        setSelectedItem(element);
                                        setModalOpen(true);
                                        setShow(true);
                                    }}>
                                        {element.concept || "-"}
                                    </div>
                                )
                            },

                        },
                        {
                            name: "Importe",
                            keyValue: "price",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {FixNumber(element.price) || "-"}€
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <div className="text-start">
                                            {element.description || "-"}
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Periodo de Tiempo",
                            keyValue: "timePeriod",
                            className: "text-center",
                            render: (element: any) => {
                                const timePeriod = element.timePeriodQuantity > 1
                                    ? element.timePeriod === 'daily' ? 'días' : element.timePeriod === 'weekly' ? 'semanas' : element.timePeriod === 'monthly' ? 'meses' : element.timePeriod === 'yearly' ? 'años' : ''
                                    : element.timePeriod === 'daily' ? 'día' : element.timePeriod === 'weekly' ? 'semana' : element.timePeriod === 'monthly' ? 'mes' : element.timePeriod === 'yearly' ? 'año' : '';
                                return (
                                    <div className="d-flex justify-content-center">
                                        {`${element.timePeriodQuantity} ${timePeriod}` || "-"} { }
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Repeticiones',
                            keyValue: 'repetitions',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.repeatsNumber || "-"}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Fecha Estimada",
                            keyValue: "estimated_entry_date",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.estimatedEntryDate.date).format('DD/MM/YYYY')}
                                    </>
                                )
                            },
                        },
                        /* {
                            name: "Fecha de Creación",
                            keyValue: "created_at",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt.date).format('DD/MM/YYYY')}
                                    </>
                                )
                            },

                        }, */
                        {
                            name: "Tipo de Coste",
                            keyValue: "costs_type",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.costsType.name || "-"}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Impuestos",
                            keyValue: "taxes",
                            className: "text-center",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                if (element.taxes && element.taxes?.name) {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            <span className="text-muted">{element.taxes.name || "-"}:&nbsp;</span> {element.taxes.value || "0"}%
                                        </div>
                                    );
                                }
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.taxes.map((tax: any) => {
                                            return (
                                                <div key={tax.id} className="d-flex flex-column flex-nowrap justify-content-center">
                                                    <div>
                                                        <span className="text-muted">{tax.name || "-"}:&nbsp;</span>
                                                        {tax.value || "0"}%
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            className: (userCan("list", "companies") ? "text-center" : "d-none"),
                            isVisible: (userCan("list", "companies") ? true : false),
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {element.company.name || "-"}
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            buttonType: 'icon',
                            icon: "Edit",
                            additionalClasses: `text-primary ${userCan("edit", "costs_fixed") ? "" : "d-none"}`,
                            description: "Editar coste fijo",
                            callback: (item: any) => {
                                if (!userCan("edit", "costs_fixed")) return;
                                setSelectedItem(item);
                                setModalOpen(true);
                                setShow(false);
                            },
                        },
                        {
                            title: "Eliminar",
                            buttonType: 'icon',
                            icon: "Delete",
                            additionalClasses: `text-danger ${userCan("delete", "costs_fixed") ? "" : "d-none"}`,
                            description: "Eliminar Coste fijo",
                            callback: (item: any) => {
                                if (!userCan("delete", "costs_fixed")) return;
                                handleConfirmationAlert({
                                    title: "Eliminar Coste fijo",
                                    text: "¿Está seguro que desea eliminar el coste fijo?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                        {
                            title: "Sin permisos",
                            buttonType: 'icon',
                            icon: "Block",
                            additionalClasses: `text-danger ${(userCan('edit', 'costs_fixed') || userCan('delete', 'costs_fixed')) ? 'd-none' : ''}`,
                            description: "No tiene permisos para editar ni eliminar",
                            callback: (item: any) => { },
                        }
                    ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Costes Fijos</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="primary"
                        title="Crear Coste Fijo"
                        icon="Add"
                        isLight
                        className={userCan("create", "costs_fixed") ? "" : "d-none"}
                        onClick={() => {
                            if (!userCan("create", "costs_fixed")) return;
                            setModalOpen(true);
                            setSelectedItem(null);
                            setShow(false);
                        }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <FixedCostFilters filters={filters} updateFilters={updateFilters} updatePageSize={updatePageSize} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card>
                    <CardBody className="table-responsive">
                        <Fragment>{getContent()}</Fragment>
                    </CardBody>
                </Card>
            </Page>
            {modalOpen && (<CreateModalFixedCost isOpen={modalOpen} setIsOpen={setModalOpen} onClose={_onCloseModal} data={selectedItem} show={show} />)}
        </Fragment>
    );
};

export default FixedCostList;