import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BalanceService } from '../../services/balance/balanceService';
import { FixNumber } from '../../utils/fixNumber';
import { useCashFlow } from '../../pages/cash-flow/provider/CashFlowProvider';
import '../../styles/scheduler.css';

interface Item {
    id: string;
    title: string;
    estimatedEntryDate: string;
    price: number;
    typeId: string;
    level: number;
    paid: boolean;
    effectiveEntryDate: string;
    color: string;
}

interface Node {
    id: string;
    name: string;
    parent: string | null;
    children: Node[];
    incomes?: Item[];
    costs?: Item[];
}

interface ResourcesComponentProps {
    incomesTree: Node[];
    costsTree: Node[];
    days: Date[];
    mode: string;
    loadingDays: boolean;
}

const DailyBalanceComponent: React.FC<ResourcesComponentProps> = ({ incomesTree, costsTree, days, mode, loadingDays }) => {

    const { initialBalance, incomesFromInitialBalanceDate, costsFromInitialBalanceDate } = useCashFlow();

    const initialBankBalance = initialBalance?.initialBankBalance || 0;
    const initialBankBalanceDate = initialBalance?.initialBankBalanceDate?.date || new Date();

    const [balanceBefore, setBalanceBefore] = useState<number>(0);
    const [notes, setNotes] = useState<{ [key: string]: number }>({});

    /**
     * Fetch daily balances to show notes
     */
    const fetchDailyBalances = async () => {
        if (mode !== 'day') return;
        const response = (await new BalanceService().getDailyBalances(days[0].toISOString().split('T')[0], days[days.length - 1].toISOString().split('T')[0])).getResponseData();
        if (response.success) {
            const notes = response.data.reduce((acc: any, note: any) => {
                acc[note.date.split(' ')[0]] = note.quantity;
                return acc;
            }, {});
            setNotes(notes);
        } else {
            console.error(response.message);
        }
    };

    /**
     * Handle note change event
     */
    const handleNoteChange = async (index: number, value: number) => {
        if (value === 0) return;
        const date = new Date(days[index]).toISOString().split('T')[0];
        setNotes(prevNotes => ({ ...prevNotes, [date]: value }));
        const response = (await new BalanceService().updateDailyBalance(date, value)).getResponseData();
        if (response.success) {
            //toast.success('Saldo diario actualizado correctamente');
        } else {
            toast.error(response.message);
        }
    };

    /**
     * Calculate daily totals for incomes and costs
     */
    const calculateDailyTotals = (nodes: Node[], days: Date[], type: 'incomes' | 'costs') => {
        const totals = new Array(days.length).fill(0);

        const addItems = (node: Node) => {
            const items = type === 'incomes' ? node.incomes : node.costs;
            items?.forEach(item => {
                if (!item.effectiveEntryDate) return;
                const itemDate = new Date(item.effectiveEntryDate);
                days.forEach((day: any, index) => {
                    if (mode === 'day' && itemDate.toDateString() === new Date(day).toDateString()) {
                        totals[index] += item.price;
                    } else if (mode === 'week' && itemDate >= day.start && itemDate <= day.end) {
                        totals[index] += item.price;
                    } else if (mode === 'month' && itemDate >= day.start && itemDate <= day.end) {
                        totals[index] += item.price;
                    }
                });
            });
            node.children.forEach(child => addItems(child));
        };

        nodes.forEach(node => addItems(node));
        return totals;
    };

    /**
     * Calculate balance before the start date of the filters based on the initial bank balance and costs from the initial balance date to the start date
     */
    useEffect(() => {
        const calculateBalanceBefore = () => {
            let balance = initialBankBalance;
            incomesFromInitialBalanceDate?.forEach((income: any) => {
                balance += income.price;
            });
            costsFromInitialBalanceDate?.forEach((cost: any) => {
                balance -= cost.price;
            });
            setBalanceBefore(balance);
        };
        calculateBalanceBefore();
    }, [costsFromInitialBalanceDate]);

    /**
     * Calculate daily balance based on daily totals for incomes and costs
     */
    const calculateDailyBalance = (incomes: number[], costs: number[]) => {
        const balances = new Array(days.length).fill(0);
        let balance = balanceBefore;
        let i = 0;
        while (new Date(days[i]) < new Date(initialBankBalanceDate)) {
            i++;
        }
        for (let j = i; j < days.length; j++) {
            balance += incomes[j] - costs[j];
            balances[j] = balance;
        }
        return balances;
    };

    const incomesTotals = calculateDailyTotals(incomesTree, days, 'incomes');
    const costsTotals = calculateDailyTotals(costsTree, days, 'costs');
    const dailyBalances = calculateDailyBalance(incomesTotals, costsTotals);

    useEffect(() => {
        fetchDailyBalances();
    }, []);

    return (
        <>
            <tbody>
                <tr className="separator-row">
                    <td colSpan={days.length + 1} className="separator">&nbsp;</td>
                </tr>

                <tr className="total-incomes">
                    <td className="fixed-column totals fw-bold">Total Ingresos</td>
                    {incomesTotals.map((total, index) => (
                        <td key={index} className="totals">
                            {total !== 0 && `${total}€`}
                        </td>
                    ))}
                </tr>

                <tr className="total-costs">
                    <td className="fixed-column totals fw-bold">Total Costes</td>
                    {costsTotals.map((total, index) => (
                        <td key={index} className="totals">
                            {total !== 0 && `${total}€`}
                        </td>
                    ))}
                </tr>

                <tr className="daily-balance">
                    <td className="fixed-column totals fw-bold">Saldo Diario</td>
                    {dailyBalances.map((balance, index) => (
                        <td key={index} className="totals">
                            {balance !== 0 && `${FixNumber(balance)}€`}
                        </td>
                    ))}
                </tr>

                {mode === 'day' && !loadingDays && Object.keys(notes).length > 0 && (
                    <tr className="notes-row">
                        <td className="fixed-column totals fw-bold">Saldo Diario (Real)</td>
                        {days.map((_, index) => {
                            return (
                                <td key={index} className="totals">
                                    <input
                                        id={'note-input' + index}
                                        type="number"
                                        step={0.01}
                                        className="note-input"
                                        value={notes[new Date(days[index])?.toISOString().split('T')[0]] !== undefined ? notes[new Date(days[index])?.toISOString().split('T')[0]] : ''}
                                        onChange={e => setNotes(prevNotes => ({ ...prevNotes, [new Date(days[index]).toISOString().split('T')[0]]: Number(e.target.value) }))}
                                        onBlur={e => handleNoteChange(index, Number(e.target.value))}
                                        onKeyDown={e => e.key === 'Enter' && e.currentTarget.blur()}
                                    />
                                </td>
                            )
                        })}
                    </tr>
                )}

                {mode === 'day' && !loadingDays && Object.keys(notes).length > 0 && (
                    <tr className="notes-row">
                        <td className="fixed-column totals fw-bold">Diferencia</td>
                        {days.map((_, index) => (
                            <td key={index} className="balance-difference">
                                {notes[new Date(days[index])?.toISOString().split('T')[0]] !== undefined && (
                                    <span className={notes[new Date(days[index])?.toISOString().split('T')[0]] === dailyBalances[index] ? 'text-success' : 'text-danger'}>
                                        {FixNumber((notes[new Date(days[index])?.toISOString().split('T')[0]] || 0) - dailyBalances[index])}€
                                    </span>
                                )}
                            </td>
                        ))}
                    </tr>
                )}
            </tbody>
        </>
    );
};

export default DailyBalanceComponent;