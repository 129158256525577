import { useCashFlow } from "./provider/CashFlowProvider";
import { useEffect, useState } from "react";
import { Cost, Income } from "../../type/invoicing-type";
import HeaderComponent from "../../components/scheduler/HeaderComponent";
import ResourcesComponent from "../../components/scheduler/ResourcesComponent";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import { CardTitle } from "../../components/bootstrap/Card";
import IncomesFilters from "./filters/CashFlowFilters";
import { generateMonthsBetweenDates, generateWeeksBetweenDates } from "../../utils/schedulerViewMode";
import * as XLSX from 'xlsx';
import moment from "moment";
import CalendarSkeleton from "../../components/skeleton/CalendarSkeleton";
import DailyBalanceComponent from "../../components/scheduler/BalanceComponent";
import '../../styles/scheduler.css';
import { FixNumber } from "../../utils/fixNumber";

const CashFlowSchedulePage = () => {

    const { filters, incomesList, refetchIncomes, costsList, costsTypesList, refetchCosts, isLoading } = useCashFlow();

    const [months, setMonths] = useState<any[]>([]);
    const [days, setDays] = useState<any[]>([]);
    const [incomes, setIncomes] = useState<any[]>([]);
    const [incomesTree, setIncomesTree] = useState<any[]>([]);
    const [costs, setCosts] = useState<any[]>([]);
    const [costTypes, setCostTypes] = useState<any[]>([]);
    const [costTypesTree, setCostTypesTree] = useState<any[]>([]);
    const [costsTree, setCostsTree] = useState<any[]>([]);
    const [loadingDays, setLoadingDays] = useState<boolean>(false);

    /**
     * Export calendar data to Excel
     */
    const handleExportToExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet([['Presupuesto (Dirección)', 'Tipo de ingreso', 'Concepto', 'Cantidad', 'Pagado', 'Fecha estimada de pago', 'Fecha efectiva de pago']]);

        const headerStyle = {
            font: { bold: true },
        };

        // Apply styles to header
        for (let col = 0; col < 7; col++) {
            const cellRef = XLSX.utils.encode_cell({ c: col, r: 0 });
            if (!ws[cellRef]) ws[cellRef] = {};
            ws[cellRef].s = headerStyle;
        }

        // Add incomes data
        incomes.forEach(income => {
            XLSX.utils.sheet_add_aoa(ws, [[
                income.budgetName,
                income.typeName,
                income.title,
                income.price + '€',
                income.paid ? 'Sí' : 'Pendiente',
                moment(income.estimatedEntryDate).format('DD-MM-YYYY'),
                income.effectiveEntryDate ? moment(income.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A'
            ]], { origin: -1 });
        });

        // Calculate column widths for incomes
        const colWidthsIncomes = incomes.reduce((widths, income) => {
            return [
                Math.max(widths[0], income.budgetName.length),
                Math.max(widths[1], income.typeName.length),
                Math.max(widths[2], income.title.length),
                Math.max(widths[3], (income.price + '€').length),
                Math.max(widths[4], (income.paid ? 'Sí' : 'Pendiente').length),
                Math.max(widths[5], moment(income.estimatedEntryDate).format('DD-MM-YYYY').length),
                Math.max(widths[6], (income.effectiveEntryDate ? moment(income.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A').length),
            ];
        }, [0, 0, 0, 0, 0, 0, 0]);

        // Set column widths for incomes
        ws['!cols'] = colWidthsIncomes.map((w: any) => ({ wch: w + 2 }));

        // Add a blank row between tables
        const blankRow = new Array(7).fill('');
        XLSX.utils.sheet_add_aoa(ws, [blankRow], { origin: -1 });

        // Add header for costs table
        const costHeader = ['Tipo de coste', 'Concepto', 'Cantidad', 'Pagado', 'Fecha estimada de pago', 'Fecha efectiva de pago'];
        XLSX.utils.sheet_add_aoa(ws, [costHeader], { origin: -1 });

        // Apply styles to cost header
        for (let col = 0; col < costHeader.length; col++) {
            const cellRef = XLSX.utils.encode_cell({ c: col, r: incomes.length + 2 });
            if (!ws[cellRef]) ws[cellRef] = {};
            ws[cellRef].s = headerStyle;
        }

        // Add costs data
        costs.forEach(cost => {
            XLSX.utils.sheet_add_aoa(ws, [[
                cost.typeName,
                cost.title,
                cost.price + '€',
                cost.paid ? 'Sí' : 'Pendiente',
                moment(cost.estimatedEntryDate).format('DD-MM-YYYY'),
                cost.effectiveEntryDate ? moment(cost.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A'
            ]], { origin: -1 });
        });

        // Calculate column widths for costs
        const colWidthsCosts = costs.reduce((widths, cost) => {
            return [
                Math.max(widths[0], cost.typeName.length),
                Math.max(widths[1], cost.title.length),
                Math.max(widths[2], (cost.price + '€').length),
                Math.max(widths[3], (cost.paid ? 'Sí' : 'Pendiente').length),
                Math.max(widths[4], moment(cost.estimatedEntryDate).format('DD-MM-YYYY').length),
                Math.max(widths[5], (cost.effectiveEntryDate ? moment(cost.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A').length),
            ];
        }, [0, 0, 0, 0, 0, 0]);

        // Set column widths for costs
        ws['!cols'] = colWidthsCosts.map((w: any) => ({ wch: w + 2 }));

        // Create a workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Flujo de efectivo');
        XLSX.writeFile(wb, 'flujo_efectivo.xlsx');
    };

    /**
     * Generate days between two dates and group them by month
     */
    const generateDaysBetweenDates = (start: Date, end: Date, mode: string): { months: any[]; days: any[] } => {
        const months: any[] = [];
        const days: any[] = [];
        let currentDate = new Date(start);
        const currentYear = start.getFullYear();

        // Helper function to get week number
        const getWeekNumber = (date: Date) => {
            const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        };

        if (mode === 'day') {
            while (currentDate <= end) {
                const year = currentDate.getFullYear();
                const month = currentDate.toLocaleString('default', { month: 'long' });
                const day = new Date(currentDate);

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ date: day });
                days.push(day);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        } else if (mode === 'week') {
            const weeks = generateWeeksBetweenDates(start, end);

            weeks.forEach(([weekStart, weekEnd]) => {
                const startDate = new Date(weekStart);
                const endDate = new Date(weekEnd);
                const year = startDate.getFullYear();
                const month = startDate.toLocaleString('default', { month: 'long' });
                const weekNumber = getWeekNumber(startDate);

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ start: startDate, end: endDate, weekNumber });
                days.push({ start: startDate, end: endDate, weekNumber });
            });
        } else if (mode === 'month') {
            const monthsInYear = generateMonthsBetweenDates(start, end);

            monthsInYear.forEach(([monthStart, monthEnd]) => {
                const startDate = new Date(monthStart);
                const endDate = new Date(monthEnd);
                const year = startDate.getFullYear();
                const month = startDate.toLocaleString('default', { month: 'long' });

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ start: startDate, end: endDate });
                days.push({ start: startDate, end: endDate });
            });
        }

        return { months, days };
    };

    /**
    * Build tree structure for incomes based on types or budgets
    */
    const buildIncomesTree = (incomes: any[], useBudgets: boolean) => {
        const treeMap = new Map<string, any>();

        incomes.forEach(income => {
            const key = useBudgets && income.budgetId ? income.budgetId : income.typeId;
            const name = useBudgets && income.budgetId ? income.budgetName : income.typeName;

            if (!treeMap.has(key)) {
                treeMap.set(key, {
                    id: key,
                    name: name,
                    incomes: [],
                    children: [],
                });
            }
            treeMap.get(key).incomes.push(income);
        });

        return Array.from(treeMap.values());
    };

    /**
     * Build tree structure for costs types based on parent-child relationship
     */
    const buildCostsTree = (nodeTypes: any, parentId: any) => {
        let level = [];
        for (let i = 0; i < nodeTypes.length; i++) {
            if (nodeTypes[i].parent === parentId) {
                let element = nodeTypes[i];
                let subchildren = buildCostsTree(nodeTypes, nodeTypes[i].id);
                element.children = subchildren;
                level.push(element);
            }
        }
        return level;
    };

    /**
     * Build costs structure for each type in the tree
     */
    const buildCosts = (costs: any, tree: any) => {
        let level = [];

        for (let treeItem of tree) {
            treeItem.costs = [];
            for (let cost of costs) {
                if (cost.typeId === treeItem.id) {
                    treeItem.costs.push(cost);
                }
            }
            if (treeItem.children.length > 0) {
                buildCosts(costs, treeItem.children);
            }
            level.push(treeItem);
        }

        return level;
    };

    /**
     * Fill days with the generated days
     */
    const fillDays = async (days: any) => {
        setDays(days)
    };

    /**
     * Update dates when filters change to generate the calendar header
     */
    useEffect(() => {
        if (filters.filter_filters?.estimated_entry_date?.from && filters.filter_filters?.estimated_entry_date?.to && filters.filter_filters?.range_mode_view) {
            setLoadingDays(true);
            const start = new Date(filters.filter_filters.estimated_entry_date.from);
            const end = new Date(filters.filter_filters.estimated_entry_date.to);
            const { months, days } = generateDaysBetweenDates(start, end, filters.filter_filters.range_mode_view);
            setMonths(months);
            fillDays(days).then(() => setLoadingDays(false));
        }
    }, [filters.filter_filters?.estimated_entry_date?.from, filters.filter_filters?.estimated_entry_date?.to, filters.filter_filters?.range_mode_view]);

    /**
     * Update incomes when incomes list change
     */
    useEffect(() => {
        if (incomesList) {
            const newIncomes = incomesList.income?.map((income: Income) => {
                return {
                    id: income.id,
                    title: income.concept,
                    estimatedEntryDate: income.estimatedEntryDate.date,
                    price: income.price,
                    typeId: income.incomeType?.id,
                    typeName: income.incomeType?.name,
                    level: income.incomeType?.level,
                    paid: income.paid,
                    effectiveEntryDate: income.effectiveEntryDate?.date || null,
                    color: income.incomeType?.color,
                    budgetId: income.stages ? income.stages.budget.id : null,
                    budgetName: income.stages ? `${income.stages.budget.name} (${income.stages.budget.address})` : null,
                }
            });
            setIncomes(newIncomes);
        }
    }, [incomesList]);

    /**
     * Update incomes tree when incomes change
     */
    useEffect(() => {
        if (incomes && incomes.length > 0) {
            const useBudgets = incomes.some(income => income.budgetId !== null);
            const tree = buildIncomesTree(incomes, useBudgets);
            setIncomesTree(tree);
        }
    }, [incomes]);

    /**
     * Update costs when costs list change
     */
    useEffect(() => {
        if (costsList) {
            const newCosts = costsList.costs?.map((cost: Cost) => {
                return {
                    id: cost.id,
                    title: cost.concept,
                    estimatedEntryDate: cost.estimatedEntryDate.date,
                    price: cost.price,
                    typeId: cost.costsType?.id,
                    typeName: cost.costsType?.name,
                    level: cost.costsType?.level,
                    paid: cost.paid,
                    effectiveEntryDate: cost.effectiveEntryDate?.date || null,
                    color: cost.costsType?.color,
                }
            });
            setCosts(newCosts);
        }
    }, [costsList]);

    /**
     * Update costs types when costs types list change
     */
    useEffect(() => {
        if (costsTypesList) {
            const newCostsTypes = costsTypesList.costsTypes?.map((costType: any) => {
                return {
                    id: costType.id,
                    name: costType.name,
                    parent: costType.parent?.id || null,
                }
            })
            setCostTypes(newCostsTypes);
        }
    }, [costsTypesList]);

    /**
     * Update costs tree when costs types change
     */
    useEffect(() => {
        if (costTypes && costTypes.length > 0) {
            const tree = buildCostsTree(costTypes, null);
            setCostTypesTree(tree);
        }
    }, [costTypes]);

    /**
     * Update costs tree when costs change
     */
    useEffect(() => {
        if (costs && costs.length > 0) {
            const tree = buildCosts(costs, costTypesTree);
            setCostsTree(tree);
        }
    }, [costs, costTypesTree]);

    return (
        <>
            {!isLoading
                ? incomesList && (
                    <>
                        <SubHeader>
                            <SubHeaderLeft>
                                <CardTitle>Flujo de efectivo</CardTitle>
                                <SubheaderSeparator />
                                <CardTitle>
                                    <span className="text-muted fs-5">Total: </span>{FixNumber(incomesList.income?.reduce((acc: number, income: Income) => { return acc + income.price }, 0))}€
                                </CardTitle>
                                <CardTitle>
                                    <span className="title-paid fs-5">Pagado: </span>{FixNumber(incomesList.income?.reduce((acc: number, income: Income) => { return income.paid ? acc + income.price : acc }, 0))}€
                                </CardTitle>
                                <CardTitle>
                                    <span className="title-pending fs-5">Pendiente: </span>{FixNumber(incomesList.income?.reduce((acc: number, income: Income) => { return income.paid ? acc : acc + income.price }, 0))}€
                                </CardTitle>
                            </SubHeaderLeft>
                            <SubHeaderRight>
                                <IncomesFilters />
                            </SubHeaderRight>
                        </SubHeader>

                        <div className="calendar-container">
                            <div className="calendar-content">
                                <table>
                                    <HeaderComponent months={months} mode={filters.filter_filters?.range_mode_view} />
                                    <ResourcesComponent data={incomesTree} days={days} mode={filters.filter_filters?.range_mode_view} entity="incomes" refetch={refetchIncomes} />
                                    <tbody>
                                        <tr className="separator-row">
                                            <td colSpan={days.length + 1} className="separator">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                    <ResourcesComponent data={costsTree} days={days} mode={filters.filter_filters?.range_mode_view} entity="costs" refetch={refetchCosts} />
                                    <DailyBalanceComponent incomesTree={incomesTree} costsTree={costsTree} days={days} mode={filters.filter_filters?.range_mode_view} loadingDays={loadingDays} />
                                </table>
                            </div>
                        </div>
                    </>
                )
                : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}><CalendarSkeleton /></div>
            }
        </>
    );
};

export default CashFlowSchedulePage;