import { useEffect, useState } from "react";
import { Cost } from "../../../type/invoicing-type";
import HeaderComponent from "../../../components/scheduler/HeaderComponent";
import ResourcesComponent from "../../../components/scheduler/ResourcesComponent";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { CardTitle } from "../../../components/bootstrap/Card";
import { useCosts } from "./provider/CostsProvider";
import CostsFilters from "./filters/CostsFilters";
import { generateMonthsBetweenDates, generateWeeksBetweenDates } from "../../../utils/schedulerViewMode";
import * as XLSX from 'xlsx';
import Button from "../../../components/bootstrap/Button";
import moment from "moment";
import CalendarSkeleton from "../../../components/skeleton/CalendarSkeleton";
import { FixNumber } from "../../../utils/fixNumber";
import '../../../styles/scheduler.css';

const CostsSchedulerPage = () => {

    const { filters, costsList, costsTypesList, isLoading, refetchCosts } = useCosts();

    const [months, setMonths] = useState<any[]>([]);
    const [days, setDays] = useState<any[]>([]);
    const [costs, setCosts] = useState<any[]>([]);
    const [costTypes, setCostTypes] = useState<any[]>([]);
    const [costTypesTree, setCostTypesTree] = useState<any[]>([]);
    const [costsTree, setCostsTree] = useState<any[]>([]);

    /**
    * Export calendar data to Excel
    */
    const handleExportToExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet([['Tipo de ingreso', 'Concepto', 'Cantidad', 'Pagado', 'Fecha estimada de pago', 'Fecha efectiva de pago']]);

        const headerStyle = {
            font: { bold: true },
        };

        for (let col = 0; col < 8; col++) {
            const cellRef = XLSX.utils.encode_cell({ c: col, r: 0 });
            if (!ws[cellRef]) ws[cellRef] = {};
            ws[cellRef].s = headerStyle;
        }

        costs.forEach(cost => {
            XLSX.utils.sheet_add_aoa(ws, [[
                cost.typeName,
                cost.title,
                cost.price + '€',
                cost.paid ? 'Sí' : 'Pendiente',
                moment(cost.estimatedEntryDate).format('DD-MM-YYYY'),
                cost.effectiveEntryDate ? moment(cost.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A'
            ]], { origin: -1 });
        });

        const colWidths = costs.reduce((widths, cost) => {
            return [
                Math.max(widths[0], cost.typeName.length),
                Math.max(widths[1], cost.title.length),
                Math.max(widths[2], (cost.price + '€').length),
                Math.max(widths[3], (cost.paid ? 'Sí' : 'Pendiente').length),
                Math.max(widths[4], moment(cost.estimatedEntryDate).format('DD-MM-YYYY').length),
                Math.max(widths[5], (cost.effectiveEntryDate ? moment(cost.effectiveEntryDate).format('DD-MM-YYYY') : 'N/A').length),
            ];
        }, [0, 0, 0, 0, 0, 0]);

        ws['!cols'] = colWidths.map((w: any) => ({ wch: w + 2 })); // Adding padding to widths

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Gastos');
        XLSX.writeFile(wb, 'calendario_gastos.xlsx');
    };

    /**
     * Generate days between two dates and group them by month
     */
    const generateDaysBetweenDates = (start: Date, end: Date, mode: string): { months: any[]; days: any[] } => {
        const months: any[] = [];
        const days: any[] = [];
        let currentDate = new Date(start);
        const currentYear = start.getFullYear();

        // Helper function to get week number
        const getWeekNumber = (date: Date) => {
            const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        };

        if (mode === 'day') {
            while (currentDate <= end) {
                const year = currentDate.getFullYear();
                const month = currentDate.toLocaleString('default', { month: 'long' });
                const day = new Date(currentDate);

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ date: day });
                days.push(day);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        } else if (mode === 'week') {
            const weeks = generateWeeksBetweenDates(start, end);

            weeks.forEach(([weekStart, weekEnd]) => {
                const startDate = new Date(weekStart);
                const endDate = new Date(weekEnd);
                const year = startDate.getFullYear();
                const month = startDate.toLocaleString('default', { month: 'long' });
                const weekNumber = getWeekNumber(startDate);

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ start: startDate, end: endDate, weekNumber });
                days.push({ start: startDate, end: endDate, weekNumber });
            });
        } else if (mode === 'month') {
            const monthsInYear = generateMonthsBetweenDates(start, end);

            monthsInYear.forEach(([monthStart, monthEnd]) => {
                const startDate = new Date(monthStart);
                const endDate = new Date(monthEnd);
                const year = startDate.getFullYear();
                const month = startDate.toLocaleString('default', { month: 'long' });

                if (!months.some(m => m.name === month && m.year === year)) {
                    months.push({ name: month, year, days: [] });
                }

                const currentMonth = months.find(m => m.name === month && m.year === year);
                currentMonth.days.push({ start: startDate, end: endDate });
                days.push({ start: startDate, end: endDate });
            });
        }

        return { months, days };
    };

    /**
     * Build tree structure for costs types list
     */
    const buildTree = (costTypes: any, parentId: any) => {
        let level = [];
        for (let i = 0; i < costTypes.length; i++) {
            if (costTypes[i].parent === parentId) {
                let element = costTypes[i];
                let subchildren = buildTree(costTypes, costTypes[i].id);
                element.children = subchildren;
                level.push(element);
            }
        }
        return level;
    };

    /**
     * Build costs structure for each type in the tree
     */
    const buildCosts = (costs: any, tree: any) => {
        let level = [];

        for (let treeItem of tree) {
            treeItem.costs = [];
            for (let cost of costs) {
                if (cost.typeId === treeItem.id) {
                    treeItem.costs.push(cost);
                }
            }
            if (treeItem.children.length > 0) {
                buildCosts(costs, treeItem.children);
            }
            level.push(treeItem);
        }

        return level;
    };

    /**
     * Update dates when filters change to generate the calendar header
     */
    useEffect(() => {
        if (filters.filter_filters?.estimated_entry_date?.from && filters.filter_filters?.estimated_entry_date?.to && filters.filter_filters?.range_mode_view) {
            const start = new Date(filters.filter_filters.estimated_entry_date.from);
            const end = new Date(filters.filter_filters.estimated_entry_date.to);
            const { months, days } = generateDaysBetweenDates(start, end, filters.filter_filters.range_mode_view);
            setMonths(months);
            setDays(days);
        }
    }, [filters.filter_filters?.estimated_entry_date?.from, filters.filter_filters?.estimated_entry_date?.to, filters.filter_filters?.range_mode_view]);

    /**
     * Update costs when costs list change
     */
    useEffect(() => {
        if (costsList) {
            const newCosts = costsList.costs?.map((cost: Cost) => {
                return {
                    id: cost.id,
                    title: cost.concept,
                    estimatedEntryDate: cost.estimatedEntryDate.date,
                    price: cost.price,
                    typeId: cost.costsType?.id,
                    typeName: cost.costsType?.name,
                    level: cost.costsType?.level,
                    paid: cost.paid,
                    effectiveEntryDate: cost.effectiveEntryDate?.date || null,
                    color: cost.costsType?.color,
                }
            });
            setCosts(newCosts);
        }
    }, [costsList]);

    /**
     * Update costs types when costs types list change
     */
    useEffect(() => {
        if (costsTypesList) {
            const newCostsTypes = costsTypesList.costsTypes?.map((costType: any) => {
                return {
                    id: costType.id,
                    name: costType.name,
                    parent: costType.parent?.id || null,
                }
            })
            setCostTypes(newCostsTypes);
        }
    }, [costsTypesList]);

    /**
     * Update costs tree when costs types change
     */
    useEffect(() => {
        if (costTypes && costTypes.length > 0) {
            const tree = buildTree(costTypes, null);
            setCostTypesTree(tree);
        }
    }, [costTypes]);

    /**
     * Update costs tree when costs change
     */
    useEffect(() => {
        if (costs && costs.length > 0) {
            const tree = buildCosts(costs, costTypesTree);
            setCostsTree(tree);
        }
    }, [costs, costTypesTree]);

    return (
        <>
            {!isLoading
                ? costsList && (
                    <>
                        <SubHeader>
                            <SubHeaderLeft>
                                <CardTitle>Calendario de costes</CardTitle>
                                <SubheaderSeparator />
                                <CardTitle>
                                    <span className="text-muted fs-5">Total: </span>{FixNumber(costsList.costs?.reduce((acc: number, cost: Cost) => { return acc + cost.price }, 0))}€
                                </CardTitle>
                                <CardTitle>
                                    <span className="title-paid fs-5">Pagado: </span>{FixNumber(costsList.costs?.reduce((acc: number, cost: Cost) => { return cost.paid ? acc + cost.price : acc }, 0))}€
                                </CardTitle>
                                <CardTitle>
                                    <span className="title-pending fs-5">Pendiente: </span>{FixNumber(costsList.costs?.reduce((acc: number, cost: Cost) => { return cost.paid ? acc : acc + cost.price }, 0))}€
                                </CardTitle>
                                <SubheaderSeparator />
                                <Button color="secondary" isLight onClick={handleExportToExcel}>Exportar</Button>
                            </SubHeaderLeft>
                            <SubHeaderRight>
                                <CostsFilters />
                            </SubHeaderRight>
                        </SubHeader>

                        <div className="calendar-container">
                            <div className="calendar-content">
                                <table>
                                    <HeaderComponent months={months} mode={filters.filter_filters?.range_mode_view} />
                                    <ResourcesComponent data={costsTree} days={days} mode={filters.filter_filters?.range_mode_view} entity="costs" refetch={refetchCosts} />
                                </table>
                            </div>
                        </div>
                    </>
                )
                : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}><CalendarSkeleton /></div>
            }
        </>
    );
};

export default CostsSchedulerPage;