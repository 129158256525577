import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BudgetService } from "../../../services/budgets/budgetService";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { Budget } from "../../../type/budgets-type";
import BudgetsForm from "../BudgetsForm";
import moment from "moment";
import { BudgetProvider } from "../providers/BudgetProvider";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

const EditBudget: FC = () => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const currentYear = moment().year();

    const budgetService = new BudgetService();

    const [isLoading, setIsLoading] = useState(false);

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = (await budgetService.getBudget(id as string));
        return response.getResponseData() as Budget;
    }, [id]));

    const handleUpdate = async (values: Budget) => {
        setIsLoading(true);
        if (values.codeIdentifier === '') values.codeIdentifier = null;
        values.expirationDate = values.expirationDate.split(' ')[0];

        const service = (data.budgetStatus.finalStatus === true) ? budgetService.editAcceptedBudget : budgetService.editBudget;

        try {
            const response = await service(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success('Presupuesto editado correctamente');
                }, 100);
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getContent = () => {
        if (error) return <ErrorMessage message={error.message} />;

        if (data !== null) {
            const budgetData = {
                budget: data.id !== null ? data.id : "",
                codeIdentifier: data.codeIdentifier !== null ? data.codeIdentifier : "",
                name: data.name !== null ? data.name : "",
                address: data.address !== null ? data.address : "",
                client: data.client?.id !== null ? data.client.id : "",
                sponsor: data.sponsor?.id !== null ? data.sponsor?.id : "",
                code: data.code?.id !== null ? data.code.id : "",
                status: data.budgetStatus?.id !== null ? data.budgetStatus.id : "",
                company: data.company?.id !== null ? data.company.id : "",
                year: data.year !== null ? data.year : currentYear,
                series: data.serie?.id !== null ? data.serie.id : "",
                paymentMethod: data.paymentMethod !== null ? data.paymentMethod : "",
                expirationDate: data.expirationDate?.date !== null ? data.expirationDate.date : "",
                taxes: data.taxes?.id !== null ? data.taxes.id : "",
                withholdings: data.withholdings !== null ? data.withholdings : "",
                concepts: data.concept !== null ? data.concept : [],
                stages: data.stages !== null ? data.stages : [],
            };

            if (data.budgetStatus.finalStatus === true && !userCan('edit_after_accept', 'budget_status')) {
                return <ErrorMessage message="No se puede editar un presupuesto que ya se ha aceptado" forbidden />
            } else {
                return (
                    <BudgetProvider budgetData={budgetData} submitEdit={handleUpdate} edit>
                        <BudgetsForm isLoading={isLoading} />
                    </BudgetProvider>
                );
            }
        }
    };

    return <>{getContent()}</>;
};

export default EditBudget;