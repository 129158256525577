import { dashboardPagesMenu, authMenu, SuperAdminMenu, ClientMenu, FinancesMenu, calendarMenu, AccountingPagesMenu, schedulerMenu } from '../menu';
import CategoryWrapper from '../pages/categories';
import CategoryList from '../pages/categories/category-list/CategoryList';
import CreateClient from '../pages/clients/client-create';
import EditClient from '../pages/clients/client-edit';
import ClientProfile from '../pages/clients/client-profile/ClientProfile';
import CompaniesWrapper from '../pages/companies';
import CompaniesList from '../pages/companies/companies-list/CompaniesList';
import CreateCompany from '../pages/companies/company-create';
import CompanyEdit from '../pages/companies/company-edit/CompanyEdit';
import OrganizationProfile from '../pages/companies/company-profile/OrganizationProfile';
import RoleWrapper from '../pages/roles';
import RoleEdit from '../pages/roles/role-edit/RoleEdit';
import RoleList from '../pages/roles/role-list/RoleList';
import StrategyWrapper from '../pages/strategies';
import StrategyList from '../pages/strategies/strategy-list/StrategyList';
import SubsidyWrapper from '../pages/subsidy';
import SubsidyList from '../pages/subsidy/subsidy-list/SubsidyList';
import UsersWrapper from '../pages/users';
import CreateUser from '../pages/users/user-create';
import UserEdit from '../pages/users/user-edit/UserEdit';
import UsersList from '../pages/users/users-list/UsersList';
import { BudgetsWrapper } from '../pages/budgets';
import CreateBudget from '../pages/budgets/budget-create/CreateBudget';
import EditBudget from '../pages/budgets/budget-edit/EditBudget';
import FinancesWrapper from '../pages/finances';
import SeriesTaxesLists from '../pages/finances/SeriesTaxesLists';
import BudgetView from '../pages/budgets/budget-view/BudgetView';
import Page404 from '../pages/auth/Page404';
import ProjectWrapper from '../pages/projects';
import CreateProject from '../pages/projects/project-create/CreateProject';
import EditProject from '../pages/projects/project-edit/EditProject';
import { ProjectView } from '../pages/projects/project-view/ProjectView';
import TasksList from '../pages/tasks/tasks-list/TasksList';
import TasksWrapper from '../pages/tasks';
import { ContactProfile } from '../pages/clients/client-profile/ContactProfile';
import UserProfile from '../pages/users/user-profile/UserProfile';
import CodeList from '../pages/codes/code-list/CodeList';
import StatusList from '../pages/statuses/status-list/StatusList';
import DocumentsWrapper from '../pages/documents';
import DocumentsList from '../pages/documents/documents-list/DocumentsList';
import ContactsListWrapper from '../pages/clients/ContactListWrapper';
import ProjectsListWrapper from '../pages/projects/ProjectsListWrapper';
import BudgetsListWrapper from '../pages/budgets/BudgetsListWrapper';
import ContactsWrapper from '../pages/clients/contactWrapper';
import ClientsWrapper from '../pages/clients/clientWrapper';
import ClientsListWrapper from '../pages/clients/ClientListWrapper';
import { TasksCalendarWrapper } from '../pages/calendar/tasks-calendar/TasksCalendarWrapper';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { IncomesScheduleWrapper } from '../pages/incomes/scheduler/IncomesWrapper';
import IncomeTypeList from '../pages/incomes/incomes-types/income-type-list/IncomeTypeList';
import IncomeList from '../pages/incomes/income-list/IncomeList';
import { CostsWrapper } from '../pages/costs/scheduler/CostsWrapper';
import CostWrapper from '../pages/costs';
import CostTypeList from '../pages/costs/costs-types/cost-type-list/CostTypeList';
import CostList from '../pages/costs/cost-list/CostList';
import CostFixedList from '../pages/costs/fixed-costs/fixed-cost-list/FixedCostList';
import IncomesWrapper from '../pages/incomes';
import MyCompanyEdit from '../pages/companies/company-edit/MyCompanyEdit';
import { CashFlowScheduleWrapper } from '../pages/cash-flow/CashFlowWrapper';

const protectedRoutes = [

	/* ******************** Organizaciones ******************** */
	{
		path: SuperAdminMenu.companies.path,
		element: <CompaniesWrapper />,
		isProtected: true,
		access: {
			group: 'companies',
			action: 'list'
		},
		sub: [
			{
				element: <CompaniesList />,
				index: true
			},
			{
				path: ':id/perfil',
				element: <OrganizationProfile />,
			},
			{
				path: ':id/edicion',
				element: <CompanyEdit />,
			},
			{
				path: 'creacion',
				element: <CreateCompany />,
			},
		]
	},

	/* **************** Configuración de mi organización **************** */
	{
		path: '/configuracion',
		element: <CompaniesWrapper />,
		isProtected: true,
		access: {
			group: 'companies',
			action: 'admin_my_company'
		},
		sub: [{
			path: ':id',
			element: <MyCompanyEdit />,
			index: true
		}]
	},

	/* ************************ Roles ************************ */
	{
		path: authMenu.roles.path,
		element: <RoleWrapper />,
		isProtected: true,
		access: {
			group: 'roles',
			action: 'list'
		},
		sub: [
			{
				element: <RoleList />,
				index: true
			},
			{
				path: ':id/edicion',
				element: <RoleEdit />,
			}
		]
	},

	/* ******************** Usuarios ******************** */
	{
		path: authMenu.users.path,
		element: <UsersWrapper />,
		isProtected: true,
		sub: [
			{
				element: <UsersList />,
				index: true,
			},
			{
				path: ':id/perfil',
				element: <UserProfile />,
			},
			{
				path: ':id/edicion',
				element: <UserEdit />,
			},
			{
				path: 'creacion',
				element: <CreateUser />,
			}
		]
	},

	/* ***************** Calendario ***************** */
	{
		path: calendarMenu.calendar.path,
		element: <TasksCalendarWrapper />,
		isProtected: true
	},

	/* ******************** Tareas ******************** */
	{
		path: authMenu.tasks.path,
		element: <TasksWrapper />,
		isProtected: true,
		sub: [
			{
				element: <TasksList />,
				index: true,
			},
		]
	},

	/* ******************* Documentos ******************* */
	{
		path: authMenu.documents.path,
		element: <DocumentsWrapper />,
		isProtected: true,
		sub: [
			{
				element: <DocumentsList />,
				index: true,
			},
		]
	},

	/* ******************** Clientes ******************** */
	{
		path: ClientMenu.clients.path,
		element: <ClientsWrapper />,
		isProtected: true,
		sub: [
			{
				element: <ClientsListWrapper />,
				index: true,
			},
			{
				path: ':id/perfil',
				element: <ClientProfile />,
			},
			{
				path: ':id/edicion',
				element: <EditClient isClient />,
			}
		]
	},

	/* ******************** Contactos ******************** */
	{
		path: ClientMenu.contacts.path,
		element: <ContactsWrapper />,
		isProtected: true,
		sub: [
			{
				element: <ContactsListWrapper />,
				index: true,
			},
			{
				path: ':id/perfil',
				element: <ContactProfile />,
			},
			{
				path: ':id/edicion',
				element: <EditClient />,
			},
			{
				path: 'creacion',
				element: <CreateClient />,
			}
		]
	},

	/* ******************** Categorías ******************** */
	{
		path: ClientMenu.categories.path,
		element: <CategoryWrapper />,
		isProtected: true,
		sub: [
			{
				element: <CategoryList />,
				index: true,
			},
		]
	},

	/* ******************** Estrategias ******************** */
	{
		path: ClientMenu.strategy.path,
		element: <StrategyWrapper />,
		isProtected: true,
		sub: [
			{
				element: <StrategyList />,
				index: true,
			},
		]
	},

	/* ******************** Presupuestos ******************** */
	{
		path: FinancesMenu.finances.path,
		element: <BudgetsWrapper />,
		isProtected: true,
		sub: [
			{
				element: <Page404 />,
				index: true,
			},
			{
				path: FinancesMenu.budget.path,
				element: <BudgetsListWrapper />,
			},
			{
				path: 'creacion/:id',
				element: <CreateBudget />,
			},
			{
				path: 'creacion',
				element: <CreateBudget />,
			},
			{
				path: ':id/edicion',
				element: <EditBudget />,
			},
			{
				path: ':id/vista',
				element: <BudgetView />,
			}
		]
	},

	/* ******************** Proyectos ******************** */
	{
		path: FinancesMenu.project.path,
		element: <ProjectWrapper />,
		isProtected: true,
		sub: [
			{
				index: true,
				element: <ProjectsListWrapper />,
			},
			{
				path: 'creacion/:id',
				element: <CreateProject />,
			},
			{
				path: ':id/edicion',
				element: <EditProject />,
			},
			{
				path: ':id/vista',
				element: <ProjectView />,
			}
		]
	},

	/* ******************** Subvenciones ******************** */
	{
		path: FinancesMenu.subsidy.path,
		element: <SubsidyWrapper />,
		isProtected: true,
		access: {
			group: 'subsidy',
			action: 'list'
		},
		sub: [
			{
				element: <SubsidyList />,
				index: true,
			},
		]
	},

	/* ******************** Estados ******************** */
	{
		path: FinancesMenu.status.path,
		element: <FinancesWrapper />,
		isProtected: true,
		sub: [
			{
				element: <StatusList />,
				index: true,
			},
		]
	},

	/* ******************* Códigos ******************* */
	{
		path: FinancesMenu.codes.path,
		element: <FinancesWrapper />,
		isProtected: true,
		sub: [
			{
				element: <CodeList />,
				index: true,
			},
		]
	},

	/* ****************** Series e impuestos ****************** */
	{
		path: FinancesMenu.seriesTaxes.path,
		element: <FinancesWrapper />,
		isProtected: true,
		sub: [
			{
				element: <SeriesTaxesLists />,
				index: true,
			},
		]
	},

	/* ****************** Incomes ****************** */
	{
		path: AccountingPagesMenu.income_type.path,
		element: <IncomesWrapper />,
		isProtected: true,
		sub: [
			{
				element: <IncomeTypeList />,
				index: true,
			},
		]
	},
	{
		path: AccountingPagesMenu.income.path,
		element: <IncomesWrapper />,
		isProtected: true,
		sub: [
			{
				element: <IncomeList />,
				index: true,
			},
		]
	},

	/* ****************** Costs ****************** */
	{
		path: AccountingPagesMenu.cost_type.path,
		element: <CostWrapper />,
		isProtected: true,
		sub: [
			{
				element: <CostTypeList />,
				index: true,
			},
		]
	},
	{
		path: AccountingPagesMenu.cost_fixed.path,
		element: <CostWrapper />,
		isProtected: true,
		sub: [
			{
				element: <CostFixedList />,
				index: true,
			},
		]
	},
	{
		path: AccountingPagesMenu.cost.path,
		element: <CostWrapper />,
		isProtected: true,
		sub: [
			{
				element: <CostList />,
				index: true,
			},
		]
	},

	/* ******************** Dashboard ******************** */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <DashboardWrapper />,
		isProtected: true,
	},
	{
		path: schedulerMenu.cashFlowSchedule.path,
		element: <CashFlowScheduleWrapper />,
		isProtected: true,
	},
	{
		path: schedulerMenu.invoicingSchedule.path,
		element: <IncomesScheduleWrapper />,
		isProtected: true,
	},
	{
		path: schedulerMenu.costsSchedule.path,
		element: <CostsWrapper />,
		isProtected: true,
	},
];

const contents = [...protectedRoutes];

export default contents;